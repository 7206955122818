<template>
  <div class="page">
    <div class="tools-bar">
      <el-button-group> 
      </el-button-group>
 

      <div class="grow"></div>

      <el-input placeholder="搜索房间ID" v-model="room_id" style="width: 230px"
                @keyup.enter.native="onSearch" @clear="load" clearable>
        <el-button slot="append" icon="el-icon-search" @click="onSearch"/>
      </el-input>
    </div>

    <el-table class="table"
              v-loading="loading"
              :data="tableData"
              element-loading-text="获取数据中 ..." 
              stripe
              highlight-current-row> 
      <el-table-column label="创建时间" width="200" prop="create_time"/> 
      <el-table-column label="过期时间" width="200" prop="expire_time"/> 

      <el-table-column label="视频地址" width="700" >
          <template slot-scope="scope">
            <div>{{scope.row.media_url}} <copy-link :url="scope.row.media_url" /></div>
          </template>
      </el-table-column>
 

    </el-table>
 
 

  </div>
</template>

<script>

  import { export_json_to_excel } from '../plugins/Export2Excel'
  import XidLink from "@/comps/xid-link";
  import CopyLink from "@/core/components/copy-link";

  export default {
    components: {XidLink,CopyLink},
    data() {
      return {
        exportVisible: false,
        export_date_range: [],
        export_loading: false,
        inputSearch: '',
        tableData: [],
        load:false,
        page: 1,
        total: 1,
        room_id: '',
        loading: false,
        status: '正常'
      }
    },
    mounted() { 
    },
    watch: { 
    },

    methods: { 
      onClickRow({id}) {
        this.$router.push({name: 'order-info', query: {id}})
      },
      onSearch(e) {
        let msg = this.$message.info('正在搜索')
        this.loading = true
        this.$api.request('/taskapi/weixinmini/getLiveList', {
          room_id:this.room_id
        },{
          host:'https://api.ccachina.net'
        }).then(data => {
          msg.close()
          this.loading = false
          this.tableData = data.data 
          this.page = 1
          this.total = 0
        })
      },
      onCancelExport() {
        this.exportVisible = false
        this.export_loading = false
      }, 
    }
  }
</script>
